import useSettings from "app/hooks/useSettings";

import logo from "../../assets/images/logos/logo-2rate.jpg"; // Adjust the path to your logo file

export default function MatxLogo({ className }) {
  return (
    <img
      src={logo}
      alt="Rate My PI  Logo"
      className={className}
      style={{ width: "90%", height: "50%" }}
    />
  );
}
