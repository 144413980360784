// config.js
export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

//const BASE_URL = "https://naval-ardra-student0001-54b76ce1.koyeb.app";
//const BASE_URL = "http://localhost:8383";
//const BASE_URL = "https://api.rate-my-pi.com";

//export const BASE_URL = "http://localhost:8383";
export const BASE_URL = "https://api.rate-my-pi.com";
