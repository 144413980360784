import { lazy } from "react";
import { Navigate } from "react-router-dom";

import AuthGuard from "./auth/AuthGuard";
import { authRoles } from "./auth/authRoles";

import Loadable from "./components/Loadable";
import MatxLayout from "./components/MatxLayout/MatxLayout";

import sessionRoutes from "./views/sessions/session-routes";
import materialRoutes from "app/views/material-kit/MaterialRoutes";

// E-CHART PAGE
const AppEchart = Loadable(lazy(() => import("app/views/charts/echarts/AppEchart")));
// DASHBOARD PAGE
const Analytics = Loadable(lazy(() => import("app/views/dashboard/Analytics")));
// Manage Reviews
const ManageReviews = Loadable(lazy(() => import("app/views/reviews/ManageReviews")));
// Manage PI
const ManagePI = Loadable(lazy(() => import("app/views/allPIs/ManagePi")));

// Manage Reviews
const ManageUserReviews = Loadable(lazy(() => import("app/views/userReviewMgmt/UserReviewMgmt")));
// Manage Reviews Status
const ManageReviewsStatus = Loadable(lazy(() => import("app/views/reviewstatus/UserReviewStatus")));

// Manage Id Verification
const ManageIdVerification = Loadable(
  lazy(() => import("app/views/userIdVerify/UserIdVerification"))
);
// Manage Admin
const ManageAdmin = Loadable(lazy(() => import("app/views/createAdminlogin/createAdmin")));

const routes = [
  /*  {
    path: "/",
    element: <Navigate to="dashboard/default" />
  }, */
  {
    path: "/",
    element: <Navigate to="/Pi" />
  },
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...materialRoutes,
      // dashboard route
      /*   {
        path: "/dashboard/default",
        element: <Analytics />,
        auth: authRoles.admin
      }, */
      {
        path: "/Pi",
        element: <ManagePI />,
        auth: authRoles.admin
      },
      {
        path: "/reviews",
        element: <ManageReviews />,
        auth: authRoles.admin
      },

      {
        path: "/userReviews",
        element: <ManageUserReviews />,
        auth: authRoles.admin
      },

      {
        path: "/reviewStatus",
        element: <ManageReviewsStatus />,
        auth: authRoles.admin
      },
      {
        path: "/ManageIdVerification",
        element: <ManageIdVerification />,
        auth: authRoles.admin
      },
      {
        path: "/ManageAdmin",
        element: <ManageAdmin />,
        auth: authRoles.admin
      },

      // e-chart route
      {
        path: "/charts/echarts",
        element: <AppEchart />,
        auth: authRoles.editor
      }
    ]
  },

  // session pages route
  ...sessionRoutes
];

export default routes;
